<template lang="pug">
  #sayhi.mt-10
    v-container
      v-row
        v-col(sm="12", md="6").mb-10
          .d-md-flex.justify-center
            #sayhi-form
              h1 Say Hi
              v-form(
                ref="form",
                v-model="valid",
                lazy-validation
              )
                v-text-field(
                  v-model="name",
                  :rules="nameRules",
                  label="Name",
                  required
                )

                v-text-field(
                  v-model="email",
                  :rules="emailRules",
                  label="Email",
                  required
                )

                v-textarea(
                  v-model="message"
                  label="Message"
                )

                v-btn(
                  :disabled="!valid",
                  color="success",
                  @click="validate"
                ) Send
            v-snackbar(
              v-model="snackbar.show",
              :timeout="snackbar.timeout",
              :color="snackbar.color"
            )
              | {{ snackbar.text }}
        v-col(sm="12", md="6")
          .d-md-flex.justify-center
            #other-contact.d-flex.flex-column.justify-space-around.text-center
              p +886965423311
              a(href="mailto:liangshihlin@gmail.com") liangshihlin@gmail.com
              p Taipei, Taiwan ROC, 105

              #social-media-links.mt-10
                p Find me on social media
                .social-links
                  a(href="https://www.facebook.com/ll931217", target="_blank").mr-2
                    fa-icon(:icon="['fab', 'facebook']")
                  a(href="https://twitter.com/troopocalypse", target="_blank").mr-2
                    fa-icon(:icon="['fab', 'twitter']")
                  a(href="https://github.com/ll931217", target="_blank").mr-2
                    fa-icon(:icon="['fab', 'github']")
                  a(href="https://codepen.io/ll931217", target="_blank").mr-2
                    fa-icon(:icon="['fab', 'codepen']")
                  a(href="mailto:liangshihlin@gmail.com", target="_blank").mr-2
                    fa-icon(icon="envelope-open-text")
</template>

<script>
export default {
  name: 'SayHi',
  data: () => ({
    valid: false,
    name: '',
    nameRules: [
      v => !!v || 'Name is required'
    ],
    email: '',
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'Must be a valid email'
    ],
    message: '',
    formspree: 'https://formspree.io/f/xzbydbvk',
    snackbar: {
      color: 'green',
      text: '',
      timeout: 2000,
      show: false
    }
  }),
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      if (valid) {
        const formData = new FormData()
        formData.append('name', this.name)
        formData.append('email', this.email)
        formData.append('message', this.message)

        fetch(this.formspree, {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json'
          }
        }).then(() => {
          this.snackbar.text = 'Hey! Thanks for saying hi, I will get back to you as soon as possible'
          this.snackbar.timeout = 4000
          this.snackbar.show = true
          this.name = ''
          this.email = ''
          this.message = ''
        }).catch(err => {
          console.error(err)
          this.snackbar.text = 'Something went wrong, please contact me'
          this.snackbar.color = 'red'
          this.snackbar.show = true
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
#sayhi-form, #other-contact
  width: 70%

  @media screen and (max-width: 600px)
    width: 100%

#other-contact
  background-color: #2d3436
  border-radius: 8px
  color: #dfe6e9
  font-size: 1.5rem
  padding: 3rem

  a
    color: #00cec9
  
  .social-links
    font-size: 1.5rem
</style>
